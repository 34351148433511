<template>
  <Fieldset legend="แบบฟอร์มเปลี่ยนข้อมูลรหัสผ่าน">
    <form @submit.prevent="saveValidatedForm" id="validity">
      <!-- รหัสผ่านเก่า -->
      <div class="p-grid">
        <div class="p-col-12 p-md-3 p-lg-3">
          <label for="passwordOld" class="p-col-fixed">รหัสผ่านเก่า</label>
        </div>
        <div class="p-col-12 p-md-4 p-lg-4">
          <Password
            id="passwordOld"
            v-model="forms.userPasswordOld"
            placeholder="รหัสผ่านเก่า"
            :class="[validates['userPasswordOld']]"
            toggleMask
            :feedback="false"
            :required="true"
          >
          </Password>
          <InlineMessage v-if="validates['userPasswordOld']"
            >ห้ามว่าง</InlineMessage
          >
        </div>
      </div>
      <!--/รหัสผ่านเก่า -->

      <!-- รหัสผ่านใหม่ -->
      <div class="p-grid">
        <div class="p-col-12 p-md-3 p-lg-3">
          <label for="passwordNew" class="p-col-fixed">รหัสผ่านใหม่</label>
        </div>
        <div class="p-col-12 p-md-4 p-lg-4">
          <Password
            id="passwordNew"
            v-model="forms.userPasswordNew"
            placeholder="รหัสผ่านใหม่"
            :class="[validates['userPasswordNew']]"
            toggleMask
            :required="true"
          >
            <template #header>
              <h6>ใส่รหัสผ่าน</h6>
            </template>
            <template #footer>
              <Divider />
              <p class="p-mt-2">ข้อเสนอแนะ</p>
              <ul class="p-pl-2 p-ml-2 p-mt-0" style="line-height: 1.5">
                <li>ตัวพิมพ์เล็กอย่างน้อยหนึ่งตัว</li>
                <li>ตัวพิมพ์ใหญ่อย่างน้อยหนึ่งตัว</li>
                <li>ตัวเลขอย่างน้อยหนึ่งตัว</li>
                <li>ขั้นต่ำ 8 อักขระ</li>
              </ul>
            </template>
          </Password>
          <InlineMessage v-if="validates['userPasswordNew']"
            >ห้ามว่าง</InlineMessage
          >
        </div>
      </div>
      <!--/รหัสผ่านใหม่ -->

      <!-- รหัสผ่านใหม่ อีกครั้ง -->
      <div class="p-grid">
        <div class="p-col-12 p-md-3 p-lg-3">
          <label for="passwordNewConf" class="p-col-fixed"
            >รหัสผ่านใหม่ อีกครั้ง
          </label>
        </div>
        <div class="p-col-12 p-md-4 p-lg-4">
          <Password
            id="userPasswordNewConf"
            v-model="forms.userPasswordNewConf"
            placeholder="รหัสผ่านใหม่ อีกครั้ง"
            :class="[validates['userPasswordNewConf']]"
            toggleMask
            :required="true"
          >
            <template #header>
              <h6>ใส่รหัสผ่าน</h6>
            </template>
            <template #footer>
              <Divider />
              <p class="p-mt-2">ข้อเสนอแนะ</p>
              <ul class="p-pl-2 p-ml-2 p-mt-0" style="line-height: 1.5">
                <li>ตัวพิมพ์เล็กอย่างน้อยหนึ่งตัว</li>
                <li>ตัวพิมพ์ใหญ่อย่างน้อยหนึ่งตัว</li>
                <li>ตัวเลขอย่างน้อยหนึ่งตัว</li>
                <li>ขั้นต่ำ 8 อักขระ</li>
              </ul>
            </template>
          </Password>
          <InlineMessage v-if="validates['userPasswordNewConf']"
            >ห้ามว่าง</InlineMessage
          >
        </div>
      </div>
      <!--/รหัสผ่านใหม่ อีกครั้ง -->

      <!-- ปุ่ม ตกลง -->
      <div class="p-grid">
        <div class="p-col-8 p-offset-3">
          <Button
            label="ยืนยันการแก้ไขข้อมูล"
            type="submit"
            icon="pi pi-save"
            class="p-button-sm p-button-success"
          />
          <Button
            label="ไม่ทำรายการ"
            icon="pi pi-arrow-left"
            class="p-button-sm p-button-link"
            @click="$router.go(-1)"
          />
        </div>
      </div>
      <!-- /ปุ่ม ตกลง -->
    </form>
  </Fieldset>
</template>
<script>
import { initCustomValidity } from '@/HTMLElement'
export default {
  name: 'changePassword',
  layout: 'Private',
  data() {
    return {
      dialog: {
        isOpen: false,
        password: null
      },
      forms: {
        userName: '',
        userPasswordOld: '',
        userPasswordNew: '',
        userPasswordNewConf: ''
      }
    }
  },
  computed: {
    validates() {
      const fields = Object.keys(this.forms).reduce((fields, value) => {
        const _value = this.forms[[value]]
        return {
          ...fields,
          [value]: _value != null && _value != '' ? '' : 'p-invalid'
        }
      }, {})
      console.log('fields ::==', fields)
      return fields
    }
  },
  mounted() {
    const { codeId, userLevel, userName } = this.$store.getters['oauth/session']
    if (codeId != null) {
      this.forms.userName = userName
    } else {
      this.$router.push({ path: '/login' })
    }
    initCustomValidity('validity')
  },
  methods: {
    isValidateForms() {
      const invalids = Object.keys(this.forms).filter((key) => {
        return !this.forms[key] || this.forms[key] == ''
      })
      return invalids
    },
    async saveValidatedForm() {
      const requireds = this.isValidateForms()
      console.log('requireds ::==', requireds)
      if (requireds.length > 0) {
        this.$toast.add({
          severity: 'error',
          summary: 'สถานะการทำรายการ',
          detail: 'กรุณากรอกข้อมูลให้เรียบร้อย ก่อนการกด ยืนยันการแก้ไขข้อมูล ',
          life: 3000
        })
        return
      }

      //confirm before  save
      this.$confirm.require({
        ...this.$primevue.config.locale,
        reject: async () => {
          const {
            status,
            code,
            invalidCode,
            data
          } = await this.$store.dispatch('user/updateUserPassword', {
            ...this.forms,
            securePassword: this.dialog.password
          })
          console.log('status ::==' + JSON.stringify(status))
          console.log('data ::==' + JSON.stringify(data))
          console.log('code ::==' + JSON.stringify(code))
          console.log('invalidCode ::==' + JSON.stringify(invalidCode))
          if (code == 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'สถานะการทำรายการ ',
              detail: 'เปลี่ยนรหัสผ่านของคุณ สำเร็จ',
              life: 3000
            })
            this.dialog.isOpen = false
            this.dialog.password = null
          } else {
            /*
             * 101 = profile not found
             * 102 = secure pass invalid
             * 103 = old pass invalid
             */
            let message =
              'เปลี่ยนรหัสผ่านของคุณ ไม่สำเร็จ กรุณาติดต่อผู้ดูแลระบบ'
            if ('101' == invalidCode) {
              message = 'ไม่พบข้อมูลของคุณในระบบ'
            } else if ('102' == invalidCode) {
              message = 'รหัสผ่านเก่า ไม่ถูกต้อง'
            } else if ('103' == invalidCode) {
              message = 'รหัสผ่านใหม่ ไม่ตรงกัน กรุณาตรวจสอบ'
            }
            this.$toast.add({
              severity: 'error',
              summary: 'สถานะการทำรายการ',
              detail: message,
              life: 3000
            })
          }
        }
      })
    }
  }
}
</script>